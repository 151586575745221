import GATSBY_COMPILED_MDX from "/home/jenkins/workspace/e-GMBH_martech.us-website_master/src/content/content-pages/affordable.md";
import React from 'react';
import {graphql} from 'gatsby';
import CustomBreadcrumb from '../components/CustomBreadcrumb';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import Hero from '../components/content/Hero';
const ContentPageTemplate = ({pageContext, location, data: {mdx}, children}) => {
  const {breadcrumb: {crumbs}} = pageContext;
  return React.createElement(Layout, {
    fontSerif: true
  }, React.createElement(Seo, {
    title: mdx.frontmatter.metaTitle,
    description: mdx.frontmatter.metaDescription,
    image: mdx.frontmatter.image
  }), React.createElement("div", {
    className: "o-wrapper"
  }, React.createElement("div", {
    className: "o-row-2p5"
  }, React.createElement(Hero, {
    headline: mdx.frontmatter.headline,
    imgSrc: mdx.frontmatter.image,
    mobileImage: mdx.frontmatter.mobileImage,
    imageAlt: mdx.frontmatter.imageAlt
  })), React.createElement("div", {
    className: "o-row-2p5"
  }, React.createElement(CustomBreadcrumb, {
    location: location,
    crumbs: crumbs
  })), React.createElement("div", {
    className: "o-row-4"
  }, React.createElement("div", {
    className: "c-content-page"
  }, children))));
};
ContentPageTemplate
export default function GatsbyMDXWrapper(props) {
  return React.createElement(ContentPageTemplate, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export const pageQuery = graphql`
	query ($slug: String!) {
		mdx(frontmatter: { slug: { eq: $slug } }) {
			frontmatter {
				metaTitle
				metaDescription
				image
				mobileImage
				imageAlt
				headline
			}
			tableOfContents(maxDepth: 2)
		}
	}
`;
